import React from "react";
import './timeline.css'
import timelinegraph from '../Z-IMAGES/timeline graph.png'


export default function TimelineISt() {




    return (
        <>




        
<div className="col-12 timeline-desktop-section-ist">
                <h1 className="text-center  pt-5" style={{ color: "#2f4d2a" }}>The Journey So Far...</h1>
                <img src={timelinegraph} className="img-fluid img-timeline" alt="Smart stock"/>
            </div>

            {/* mobile view */}
            <section className=" timeline-mobile-section-ist">
                <div class="timeline container-fluid">
                    <h1 className="text-center  pt-5 " style={{ color: "#2f4d2a" }}>The Journey So Far...</h1>
                    <ol className="py-5">

                        <li>
                            <div>
                                <time>2011</time> With our strong roots from Coimbatore, TN we sprouted up as IST Seller in E-commerce business through eBay.
                            </div>

                        </li>
                        <li>
                            <div>
                                <time>2014</time> Sustained & Strong with 500+ Happy retail customers & 15 Products in catalogue.
                            </div>
                        </li>
                        <li>
                            <div>
                                <time>2017</time> Brand registered as <pre>IST RETAIL</pre>
                                Started selling with Amazon & Flipkart.
                            </div>
                        </li>
                        <li>
                            <div>
                                <time>2018</time> Catalog widened to 40+ Electronics & Ecofriendly products.
                            </div>
                        </li>
                        <li>
                            <div>
                                <time>2019</time> Understanding the needs of market, We pitched into B2B & started supplies to companies.
                            </div>
                        </li>
                        <li>
                            <div>
                                <time>2020</time> New vertical Food & Beverages was launched with 5 new products for Local & Export market.
                            </div>
                        </li>
                        <li>
                            <div>
                                <time>2021</time> In Customer need based product competitive sourcing at Right cost, Quality & On time delivery.
                            </div>
                        </li>
                        <li>
                            <div >
                                <time>2022</time> New vertical Industrial Smart security & CCTV with AI added.
                            </div>
                        </li>
                        <li >
                            <div>
                                <time>2023</time> Own Data server installed for Cloud space & Stepped into Specific purpose electronics manufacturing.
                            </div>
                        </li>
                     
                        <li>
                            <div>
                                <time>2023</time> Own Data server installed for Cloud space & Stepped into Specific purpose electronics manufacturing.
                            </div>
                        </li>
                    </ol>
                </div>
            </section>








        </>
    );
}