import React, { useEffect } from 'react';
import './aboutusist.css';
import certificate1 from '../Z-IMAGES/certificates-1.jpg';
import certificate2 from '../Z-IMAGES/certificates-2.jpg'
import certificate3 from '../Z-IMAGES/certificates-3.webp'
import certificate4 from '../Z-IMAGES/certificates-4.avif'
import certificate5 from '../Z-IMAGES/certificates-5.png'
import certificate6 from '../Z-IMAGES/GEM ISTRETAIL.jpg'
import certificate7 from '../Z-IMAGES/SAPAR ISTRETAIL.jpg'
import certificate8 from '../Z-IMAGES/iec-code-import-export-code-250x250.webp'
import Footerist from '../A-LAYOUT/footer';
import ISTLOGO from '../Z-IMAGES/IST-LOGO (2).jpeg';



export default function Aboutusist() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <>


            <section class="about-section">
                <div class="container">
                    <div class="row ">


                        <div class="content-column col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="inner-column">
                                <div class="sec-title">

                                    <h2 >Welcome    to   <span className='isttext'>IST RETAIL</span></h2>
                                </div>
                                <div class="text">
                                    <p>    <span className='isttext'>IST RETAIL</span>,  headquartered in Coimbatore, stands for Invent Smart Technologies. Led by a dynamic woman entrepreneur, we boast over a decade of experience in retail operations across e-commerce platforms and direct sales channels. Our guiding principles—Best Quality, Right Cost, and On-Time Delivery—are the cornerstones of our business philosophy.
                                    </p>
                                    <p>    Carefully curated, our product selection undergoes rigorous scrutiny to ensure it not only meets but surpasses customer expectations. We are committed to offering competitive prices without compromising on quality, making cost-effectiveness a priority. Leveraging our efficient logistics network, we guarantee prompt delivery, maintaining utmost customer satisfaction.
                                    </p>
                                    <p>   Driven by market dynamics, we continuously evolve our offerings to cater to diverse preferences. Be it through our user-friendly online platforms or personalized direct sales approach, our overarching mission remains steadfast—to deliver unparalleled service and premium products to our esteemed clientele.</p>
                                </div>


                            </div>
                        </div>


                        <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-center justfy">

                            <div class="">
                                <img src={ISTLOGO} alt="" className='img-fluid' />

                            </div>

                        </div>

                    </div>
                </div>
            </section>

            <section className='container certificaties-card-ist-about text-center'>
                <h1 className="head-h-divtopic py-5" >
                    We are     <span style={{ color: "#f28c28", fontWeight: "bolder" }}> registered & certified     </span> for
                </h1>

                <div class="card-group">
                    <div class="card">
                        <img src={certificate1} alt='ist-product' />
                        <div class="card-body">
                        </div>
                    </div>
                    <div class="card">
                        <img src={certificate2} alt='ist-product' />
                        <div class="card-body">

                        </div>
                    </div>

                </div>
                <div class="card-group">
                    <div class="card">
                        <img src={certificate3} alt='ist-product' />
                        <div class="card-body">
                        </div>
                    </div>
                    <div class="card">
                        <img src={certificate4} alt='ist-product' />
                        <div class="card-body">
                        </div>
                    </div>

                </div>
                <div class="card-group">
                    <div class="card">
                        <img src={certificate5} alt='ist-product' />
                        <div class="card-body">

                        </div>
                    </div>
                    <div class="card">
                        <img src={certificate6} alt='ist-product' />
                        <div class="card-body">
                        </div>
                    </div>


                </div>
                <div class="card-group">
                    <div class="card">
                        <img src={certificate7} alt='ist-product' />
                        <div class="card-body">

                        </div>
                    </div>
                    <div class="card">
                        <img src={certificate8} alt='ist-product' />
                        <div class="card-body">
                        </div>
                    </div>

                </div>
            </section>


            <section className='testimonal-section-ist '>


                <h1 className="head-h-divtopic py-5" style={{ fontWeight: "bolder" }} >
                    <span style={{ color: "#f28c28", fontWeight: "bolder" }}> CUSTOMERS VOICE..   </span>
                </h1>


                <div id="testimonal-section">
                    <div id="left-testimonal">

                        <div id="left-title">
                            <h1 class="testimonals-title TESTIMONALS">TESTIMONALS</h1>
                            <p class="item-text">Don't take our word for it... Read what our customers feel about our products!</p>

                        </div>
                    </div>
                    <div id="right-testimonal">
                        <div id="section1">
                            <div class="testimonals-wrapper">
                                <div class="testimonals-photo">"</div>
                                <h4 class="testi-title">Sruthi Rangaraj</h4>

                                <p class="testimonal-paragraph">
                                    “ I am very happy with the quality of the masks..And their services was too good..Also the packing was superb..All the best,keep doing the good work ”</p>
                            </div>
                            <div class="testimonals-wrapper">
                                <div class="testimonals-photo">"</div>
                                <h4 class="testi-title">Mukessh Rajendran</h4>

                                <p class="testimonal-paragraph">
                                    “Wow, what a great experience I had with you guys! Good website experience, great prices for products, speedy shipping,You guys rock”
                                </p>
                            </div>
                        </div>
                        <div id="section-2">
                            <div class="testimonals-wrapper" id="testimona-section2">
                                <div class="testimonals-photo">"</div>
                                <h4 class="testi-title">Sakthi R</h4>

                                <p class="testimonal-paragraph">
                                    “I absolutely love this product! It was a game-changer for me.”
                                </p>
                            </div>
                            <div class="testimonals-wrapper">
                                <div class="testimonals-photo">"</div>
                                <h4 class="testi-title">shijith rk</h4>

                                <p class="testimonal-paragraph">
                                    “The customer service was amazing. They solved my problem immediately!”
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Footerist />
        </>
    );
}
