import React from "react";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import { HashRouter, Routes, Route } from 'react-router-dom';
import LAYOUT from "./layout";
import Aboutusist from "../C-ABOUT-LAYOUT/aboutist";

import Servicesist from "../E-SERVICES-LAYOUT/servicesist";
import Supportist from "../F-SUPPORT-LAYOUT/supportpage";
import Isthome from "../B-HOME-LAYOUT/home";
import Products from "../D-PRODUCT-LAYOUT/Product";


export default function Istretailcom() {
    return (
        <>
            <HashRouter >
                <Routes >
                    <Route exact path="/*" element={<LAYOUT />}>
                        <Route index element={<Isthome />} />
                        <Route exact path="isthome" element={<Isthome />} />
                        <Route exact path="Aboutusist" element={<Aboutusist />} />
                        <Route exact path="istproduct" element={<Products />} />
                        <Route exact path="istservices" element={<Servicesist />} />
                        <Route exact path="istsupport" element={<Supportist />} />


                    </Route>



                </Routes>
            </HashRouter>


        </>
    );
}

