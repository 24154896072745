import React from "react";
import './navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ISTLOGO from '../Z-IMAGES/IST-LOGO (2).jpeg'
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Footerist() {
    return (
        <>


            {/* footer */}




            <body >
                <footer className="footer-sec-ist">
                    <div class="container">
                        <div class="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 logo-footer-ist">
                                <img src={ISTLOGO} alt="Smart stock" />
                            </div>
                            <div  class="col-lg-6 col-md-5 col-sm-12 footer-links " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
 
                          
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <ul className="">
                                    <li><a ><Link to="/Aboutusist" > About Us</Link></a></li>
                                    <li><a ><Link to="/istservices" > Executed Solutions</Link></a></li>
                                    <li><a ><Link to="/istsupport" >  Contact</Link></a></li>
                                    <li><a >Privacy</a></li>
                                    <li><a >Terms</a></li>
                                </ul>
                            </div>
                             </div>
                            <div class="col-lg-3 col-md-4 col-sm-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>


                                <div class="Social-media-footer ">
                                    <a href="https://www.facebook.com/ISTforyou" target="_blank"><FontAwesomeIcon icon={faFacebook} style={{ color: '#1877F2', fontSize: "20px" }} className="fonticonistsocial m-2" /></a>
                                    <a href="https://www.instagram.com/ist_retail/?hl=en" target="_blank"><FontAwesomeIcon icon={faInstagram} style={{ color: '#E4405F', fontSize: "20px" }} className="fonticonistsocial m-2" /></a>
                                    <a href="https://youtube.com/@ist_retail?si=Rt0R3LzJ3iaD7yTv" target="_blank"><FontAwesomeIcon icon={faYoutube} style={{ color: "#FF0000", fontSize: "20px" }} className="fonticonistsocial m-2" /></a>

                                    <a href="https://wa.me/919943894429" target="_blank"><FontAwesomeIcon icon={faWhatsapp} style={{ color: "#25D366", fontSize: "20px" }} className="fonticonistsocial m-2" /></a>


                                    <a href="mailto:contact@istretail.com" ><FontAwesomeIcon icon={faEnvelope} style={{ color: "#3b60c4", fontSize: "20px" }} className="m-2" /></a>
                                    <a ><Link to="/istsupport" ><FontAwesomeIcon icon={faPhone} className="m-2" /></Link></a>

                                </div>

                            </div>
                            <hr className="hr-footer-ist" />
                            <div className="text-center">


                                <p style={{ fontSize: "small" }}>  &reg; 2024 <span className='isttext' style={{ color: "#fff" }}>IST RETAIL</span>  ,  All rights reserved.</p>
                                <p style={{ fontSize: "small" }}>This Website and the contents of this Website are proprietary & confidential information of <span className='isttext' style={{ color: "#fff" }}>IST RETAIL</span> (Trademark registered).<br /> It is not intended to be distributed to any third party without the written contest of <span className='isttext' style={{ color: "#fff" }}>IST RETAIL</span></p>
                            </div>
                        </div>
                    </div>
                </footer>

            </body>












        </>
    )
}