export const categories = [
  {
    id: 1,
    name: 'Electrical and Electronics',
    image: [
      require('../Z-IMAGES/dairy.png'),

    ],
    subcategories: [
      { id: 1, name: 'Biometric door locks & Attendance systems' },
      { id: 2, name: 'Specific purpose Electronics' },
      { id: 3, name: 'Water Pumps (Dom. & Ind.)' },
      { id: 4, name: 'Data & Power Cables' },
      { id: 5, name: 'Test equipment’s for E Lab' },
      { id: 6, name: 'Office & Personal computers' }
    ]
  },
  {
    id: 2,
    name: 'Smart solutions',
    image: [
      require('../Z-IMAGES/dairy.png'),

    ],
    subcategories: [
      { id: 1, name: 'Standalone CCTV’s | Road safety with AI ' },
      { id: 2, name: 'WIFI/LoRa connect sensor network' },
      { id: 3, name: 'Smart classrooms / Business conference rooms' },
      { id: 4, name: 'Cloud space' },
      { id: 5, name: 'Smart Light | Switch | Socket' }
    ]
  },
  {
    id: 3,
    name: 'Food Products',
    image: [
      require('../Z-IMAGES/dairy.png'),

    ],
    subcategories: [
      { id: 1, name: 'Organic Coriander powder' },
      { id: 2, name: 'Organic Turmeric powder' },
      { id: 3, name: 'Pure Indian pepper ' },
      { id: 4, name: 'Raw coconut' }
    ]
  },
  {
    id: 4,
    name: 'Other verticals',
    image: [
      require('../Z-IMAGES/dairy.png'),

    ],
    subcategories: [
      { id: 1, name: 'Workplace PPE’s' },
      { id: 2, name: 'Laser engraving' },
      { id: 3, name: 'PVC / RFID Card printing ' },
      { id: 4, name: 'Food Products (Export)' },
      { id: 5, name: 'Hygiene needs - Disinfectants' }
    ]
  },


];

export const products = [
  {
    id: 1,
    category: 1,
    subcategory: 1,
    name: ' Door lock ',
    name1: 'Plug In',
    description: 'Biometric door locks and attendance systems enhance security with fingerprint and facial recognition.',
    images: [
      require('../Z-IMAGES/ist-products/Ist-product-1.png'),

    ]

  },
  {
    id: 1,
    category: 1,
    subcategory: 1,
    name: ' Biometric ',
    name1: 'Plug In',
    description: 'Biometric door locks and attendance systems enhance security with fingerprint and facial recognition.',
    images: [
      require('../Z-IMAGES/ist-products/biometric-1.png'),

    ]

  },
  {
    id: 2,
    category: 1,
    subcategory: 2,
    name: 'Specific purpose Electronics',
    name1: 'Specific purpose Electronics',
    description: 'Specific purpose electronics are designed to perform specialized functions efficiently and reliably.',
    images: [
      require('../Z-IMAGES/ist-products/Ist-product-1.png'),

    ]

  },
  {
    id: 3,
    category: 1,
    subcategory: 3,
    name: ' Smart Water Pumps ',
    name1: 'Battery',
    description: 'Water pumps (domestic and industrial) provide efficient water transfer and distribution for various applications.',
    images: [
      require('../Z-IMAGES/ist-products/smart-water-pump.png'),

    ]

  },
  {
    id: 4,
    category: 1,
    subcategory: 4,
    name: 'Data  Cables',
    name1: '20V',
    description: 'Data and power cables ensure reliable transmission of information and electrical power.',
    images: [
      require('../Z-IMAGES/ist-products/patch-cable.png'),

    ]

  },
  {
    id: 4,
    category: 1,
    subcategory: 4,
    name: ' Power Cables',
    name1: '25mm',
    description: 'Data and power cables ensure reliable transmission of information and electrical power.',
    images: [
      require('../Z-IMAGES/ist-products/power-cable.png'),

    ]

  },
  {
    id: 5,
    category: 1,
    subcategory: 5,
    name: 'Test Equipment’s ',
    name1: ' For E-Lab',
    description: 'Test equipment for E labs enables precise measurement and analysis of electronic components and circuits.',
    images: [
      require('../Z-IMAGES/ist-products/test-equipments.png'),

    ]

  },
  {
    id: 6,
    category: 1,
    subcategory: 6,
    name: ' Computers',
    name1: 'Office & Personal computers',
    description: 'Office and personal computers facilitate productivity and connectivity for work and daily use.',
    images: [
      require('../Z-IMAGES/ist-products/pc.png'),

    ]

  },
  {
    id: 7,
    category: 2,
    subcategory: 1,
    name: ' CCTV’s ',
    name1: 'Standalone  ',
    description: 'Standalone CCTV systems offer surveillance, while AI-enabled road safety tech enhances traffic monitoring.',
    images: [
      require('../Z-IMAGES/ist-products/camera.png'),

    ]

  },
  {
    id: 7,
    category: 2,
    subcategory: 1,
    name: ' CCTV’s ',
    name1: 'Security Purpose  ',
    description: 'Standalone CCTV systems offer surveillance, while AI-enabled road safety tech enhances traffic monitoring.',
    images: [
      require('../Z-IMAGES/ist-products/camera-2.png'),

    ]

  },
  {
    id: 7,
    category: 2,
    subcategory: 1,
    name: ' Door Camera ',
    name1: 'CCTV’s  ',
    description: 'Standalone CCTV systems offer surveillance, while AI-enabled road safety tech enhances traffic monitoring.',
    images: [
      require('../Z-IMAGES/ist-products/Door-camera.png'),

    ]

  },
  {
    id: 7,
    category: 2,
    subcategory: 1,
    name: 'Indoor Camera ',
    name1: 'CCTV’s   ',
    description: 'Standalone CCTV systems offer surveillance, while AI-enabled road safety tech enhances traffic monitoring.',
    images: [
      require('../Z-IMAGES/ist-products/indoor-camera.png'),

    ]

  },
  {
    id: 8,
    category: 2,
    subcategory: 2,
    name: 'WIFI / LoRa connect sensor network ',
    name1: 'WIFI / LoRa connect sensor network ',
    description: 'WiFi/LoRa-connected sensor networks enable efficient and reliable data transmission for various applications.',
    images: [
      require('../Z-IMAGES/ist-products/Ist-product-1.png'),

    ]

  },
  {
    id: 9,
    category: 2,
    subcategory: 3,
    name1: 'Smart classrooms ',
    name: 'Projecter ',
    description: 'Smart classrooms integrate technology to enhance teaching and learning experiences. ',
    images: [
      require('../Z-IMAGES/ist-products/projectors.png'),

    ]


  },
  {
    id: 9,
    category: 2,
    subcategory: 3,
    name1: 'Smart classrooms ',
    name: 'Laptop ',
    description: 'Smart classrooms integrate technology to enhance teaching and learning experiences. ',
    images: [
      require('../Z-IMAGES/ist-products/laptop.png'),

    ]


  },
  {
    id: 10,
    category: 2,
    subcategory: 3,
    name: 'Electric Equipments ',
    name1: ' Business conference',
    description: 'Business conference rooms are equipped with advanced technology to facilitate effective meetings and presentations.',
    images: [
      require('../Z-IMAGES/ist-products/rent-laptop.png'),

    ]

  },
  {
    id: 11,
    category: 2,
    subcategory: 5,
    name: 'Smart Switch  ',
    name1: 'Battery ',
    description: 'This Provide convenient and energy-efficient control over home and office environments.',
    images: [
      require('../Z-IMAGES/ist-products/smart switch.jpg'),

    ]

  },
  {
    id: 11,
    category: 2,
    subcategory: 5,
    name: 'Smart Socket  ',
    name1: 'Wireless ',
    description: 'This Provide convenient and energy-efficient control over home and office environments.',
    images: [
      require('../Z-IMAGES/ist-products/smart-socket.png'),

    ]

  },
  {
    id: 11,
    category: 2,
    subcategory: 5,
    name: 'Smart Lighting  ',
    name1: 'Solor ',
    description: 'This Provide convenient and energy-efficient control over home and office environments.',
    images: [
      require('../Z-IMAGES/ist-products/smart-lighting.png'),

    ]

  },
  {
    id: 12,
    category: 4,
    subcategory: 1,
    name: 'Eye & Face Protector ',
    name1: 'Workplace PPE’s ',
    description: 'Workplace PPE ensures safety and protection for employees in various occupational environments.',
    images: [
      require('../Z-IMAGES/ist-products/ppe-1.png'),

    ]

  },
  
  {
    id: 12,
    category: 4,
    subcategory: 1,
    name: 'Ear Protector ',
    name1: 'Workplace PPE’s ',
    description: 'Workplace PPE ensures safety and protection for employees in various occupational environments.',
    images: [
      require('../Z-IMAGES/ist-products/ppe-4.png'),

    ]

  },
  {
    id: 12,
    category: 4,
    subcategory: 1,
    name: 'Head Protector ',
    name1: 'Workplace PPE’s ',
    description: 'Workplace PPE ensures safety and protection for employees in various occupational environments.',
    images: [
      require('../Z-IMAGES/ist-products/ppe-2.png'),

    ]

  },
  {
    id: 12,
    category: 4,
    subcategory: 1,
    name: 'Glove ',
    name1: 'Workplace PPE’s ',
    description: 'Workplace PPE ensures safety and protection for employees in various occupational environments.',
    images: [
      require('../Z-IMAGES/ist-products/ppe-3.png'),

    ]

  },
  {
    id: 13,
    category: 4,
    subcategory: 2,
    name: 'Lazer engraving ',
    name1: 'All materials ',
    description: 'Laser engraving provides precise and permanent markings on various materials.',
    images: [
      require('../Z-IMAGES/ist-products/laser-printer.png'),

    ]

  },
  {
    id: 14,
    category: 4,
    subcategory: 3,
    name: 'PVC  RFID Card printing ',
    name1: 'PVC  RFID Card printing ',
    description: 'PVC RFID card printing enables custom, scannable identification cards.',
    images: [
      require('../Z-IMAGES/ist-products/card-pronter.png'),

    ]

  },
  {
    id: 15,
    category: 4,
    subcategory: 5,
    name: 'Hygiene needs - (Disinfectants) ',
    name1: 'Hygiene needs - (Disinfectants) ',
    description: 'Hygiene needs are met with effective disinfectants for clean and safe environments.',
    images: [
      require('../Z-IMAGES/ist-products/Ist-product-1.png'),

    ]

  },
  {
    id: 16,
    category: 3,
    subcategory: 1,
    name: ' Coriander powder ',
    name1: 'Organic',
    description: 'Organic coriander powder adds natural flavor and aroma to culinary dishes.',
    images: [
      require('../Z-IMAGES/ist-products/coriender.png'),

    ]

  },
  {
    id: 17,
    category: 3,
    subcategory: 2,
    name: 'Turmeric powder ',
    name1: 'Natural ',

    description: 'Organic turmeric powder offers natural flavor and health benefits to culinary dishes.',
    images: [
      require('../Z-IMAGES/ist-products/turmeric.png'),

    ]

  },
  {
    id: 18,
    category: 3,
    subcategory: 3,
    name: ' Indian pepper',
    name1: 'Pure ',
    description: 'Pure Indian pepper enhances dishes with its authentic, robust flavor.',
    images: [
      require('../Z-IMAGES/ist-products/pepper.png'),

    ]

  },
  {
    id: 19,
    category: 3,
    subcategory: 4,
    name: ' coconut',
    name1: 'Raw & Nature',
    description: 'Raw coconut provides fresh, versatile ingredients for culinary and health applications.',
    images: [
      require('../Z-IMAGES/ist-products/coconut.png'),

    ]

  },
  {
    id: 20,
    category: 2,
    subcategory: 4,
    name: 'Cloud ',
    name1: 'Storage',
    description: 'Raw coconut provides fresh, versatile ingredients for culinary and health applications.',
    images: [
      require('../Z-IMAGES/ist-products/wifi-cloud.png'),

    ]

  },

];