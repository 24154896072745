import React, { useEffect, useState } from "react";
import './services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import flow from '../Z-IMAGES/flowchart.gif';

import img1 from '../Z-IMAGES/ist-images/ist-images/image-1.png';
import img2 from '../Z-IMAGES/ist-images/ist-images/image-2.png';
import img3 from '../Z-IMAGES/ist-images/ist-images/image-3.png';
import img4 from '../Z-IMAGES/ist-images/ist-images/image-4.png';
import img5 from '../Z-IMAGES/ist-images/ist-images/image-5.png';
import img6 from '../Z-IMAGES/ist-images/ist-images/image-6.png';
import img7 from '../Z-IMAGES/ist-images/ist-images/image-7.png';
import img8 from '../Z-IMAGES/ist-images/ist-images/image-8.png';
import img9 from '../Z-IMAGES/ist-images/ist-images/image-9.png';
import img10 from '../Z-IMAGES/ist-images/ist-images/image-10.png';
import img11 from '../Z-IMAGES/ist-images/ist-images/image-11.png';
import img12 from '../Z-IMAGES/ist-images/ist-images/image-12.png';
import img13 from '../Z-IMAGES/ist-images/ist-images/image-13.png';

import btn1 from '../Z-IMAGES/services-img-btn (1).png';
import btn2 from '../Z-IMAGES/services-img-btn (2).png';
import textdivi1 from '../Z-IMAGES/blog-data-visualisation_0.jpg';
import textdivi2 from '../Z-IMAGES/7R9TP31ewk23T2Y0bGhSF-transformed.jpg';
import textdivi3 from '../Z-IMAGES/industries-retail-ecommerce-tier-img (1).jpg';
import chartvideo from '../Z-IMAGES/chart-video-ist.gif';
import Footerist from "../A-LAYOUT/footer";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Keyboard, Scrollbar, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';




export default function Servicesist() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [activeTab, setActiveTab] = useState('supplyChain');


    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ src: '', alt: '' });

    const handleImageClick = (src, alt) => {
        setSelectedImage({ src, alt });
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedImage({ src: '', alt: '' });
    };


    return (
        <>




            <div>
                <section className="container services-top-nav-ist">
                    <div className="row">
                        <div className="col-lg-6  col-md-6 col-sm-12">
                            <button onClick={() => setActiveTab('supplyChain')} className="col-8">
                                <img src={btn1} alt="Supply Chain Chart" className="img-fluid " style={{ height: "fit-content", width: "150px" }} />
                                <p>Supply chain <span>partnering</span></p>
                            </button>
                        </div>
                        <div className="col-lg-6  col-md-6 col-sm-12">

                            <button onClick={() => setActiveTab('eSecurity')} className="col-8">
                                <img src={btn2} alt="Supply Chain Chart" className="img-fluid " style={{ height: "fit-content", width: "150px" }} />
                                <p>Smart <span>E-security</span></p>
                            </button>
                        </div>
                    </div>
                </section>

                {activeTab === 'supplyChain' && (
                    <>

                        <section className="container-fluid ">
                            <h1 className="text-center head-h-divtopic pt-5 py-5" style={{ color: "#2f4d2a" }}>
                                <span style={{ color: "#f28c28", fontWeight: "bolder" }}> Supply chain partnering </span>
                            </h1>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img src={chartvideo} alt="Supply Chain Chart" className="img-fluid mb-5" style={{ height: "fit-content", width: "600px" }} />
                            </div>
                        </section>



                        <div className="card rlcard-textimg-services-ist">
                            <div className="row g-0">
                                <div className="col-lg-6 col-md-6 ">
                                    <img src={textdivi1} className="img-fluid" width="100%" alt="Smart stock" />
                                </div>
                                <div className="col-lg-6 col-md-6  imtext-c col-sm-12ard-ist-ser">
                                    <div className="card-body">
                                        <h1>Smart stock</h1>
                                        <p>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} /> We refill your stock based on minimum level monitoring by remote sensor network.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} /> S1 <FontAwesomeIcon icon={faAngleDoubleRight} style={{ color: "#F28C28" }} /> Buy only when you need & S2 <FontAwesomeIcon icon={faAngleDoubleRight} style={{ color: "#F28C28" }} /> Bill only when you consume.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card rlcard-textimg-services-ist">
                            <div className="row g-0">
                                <div className="col-lg-6 col-md-6  imtext-c col-sm-12ard-ist-ser order-2 order-lg-1">
                                    <div className="card-body">
                                        <h1>Competitive alternative sourcing</h1>
                                        <p>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} /> We source your requirements 1:1 with better cost (Only based on approval).
                                        </p>
                                        <p>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} /> Localization of Import components.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6  order-1  col-sm-12order-lg-2">
                                    <img src={textdivi2} className="img-fluid" width="100%" alt="Competitive alternative sourcing" />
                                </div>
                            </div>
                        </div>

                        <div className="card rlcard-textimg-services-ist">
                            <div className="row g-0">
                                <div className="col-lg-6 col-md-6 ">
                                    <img src={textdivi3} className="img-fluid" width="100%" alt="Seamless communication" />
                                </div>
                                <div className="col-lg-6 col-md-6  imtext-c col-sm-12ard-ist-ser">
                                    <div className="card-body">
                                        <h1>Seamless<br /> communication</h1>
                                        <p>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} /> We are available (7to7 x 7D) to hear you through,<br /> E-Mail / Call / WhatsApp / Video conference.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} /> Clear Escalation matrix shared upfront to customers. | TAT : 2 Hours.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <section className="text-center mt-5 mb-5" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div className="col-lg-10">
                                <img src={flow} alt="Flow chart" className="img-fluid" />
                            </div>
                            <h1 className="text-center head-h-divtopic pt-5 py-5" style={{ color: "#2f4d2a" }}>
                                Provide us an <span style={{ color: "#f28c28", fontWeight: "bolder" }}>opportunity to experience</span> the difference.
                            </h1>
                        </section>


                        <div class="data-container text-center">
                            <button type="button" class="btn btn-secondary"> <a href="https://www.procureg.com/" className="" target="-blank" style={{ color: "white" }}>
                                Click to visit our dedicated  website
                            </a></button>
                            {/* 
                            <span class="btn-ist-ser-pro"> <a href="https://www.procureg.com/" className="" target="-blank">
                                Click to visit our dedicated  website
                            </a></span> */}
                        </div>



                        <h1 className="text-center text-secondary">Our Recent Projects</h1>







                        <Swiper
                            centeredSlides={true}

                            grabCursor={false}
                            keyboard={{
                                enabled: true,
                            }}
                            spaceBetween={30}
                            loop={true}
                            breakpoints={{
                                250: {
                                    slidesPerView: 1,
                                    slidesPerGroup: 1,
                                
                                },
                                500: {
                                  
                                    slidesPerView: 3,
                                    slidesPerGroup: 1,
                                
                                },
                                750: {
                                    slidesPerView: 4,
                                    slidesPerGroup: 1,
                                
                                },
                                1000: {
                                    slidesPerView: 5,
                                    slidesPerGroup: 1,
                                
                                },
                            }}
                            autoplay={{
                                delay: 1000,
                                disableOnInteraction: false,
                            }}

                            modules={[Keyboard, Scrollbar, Navigation, Pagination, Autoplay]}
                            className="mySwiper-ist mb-4"
                        >
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img1}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img2}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img3}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img4}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img5}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img6}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img7}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img8}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img9}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img10}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img11}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img12}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                            <SwiperSlide className="mySwiper-ist-slider">
                                <img
                                    id="myImg"
                                    src={img13}
                                    alt="Your  description"
                                    onClick={(e) => handleImageClick(e.target.src, e.target.alt)}
                                    style={{ width: '100%', maxWidth: '300px', cursor: 'pointer' }}
                                />

                            </SwiperSlide>
                        </Swiper>


                        {isOpen && (
                            <div className="modal-overlay">
                                <div className="modal-content">
                                    <button onClick={closeModal} className="modal-close-button">&times;</button>
                                    <img src={selectedImage.src} alt={selectedImage.alt} className="max-w-half max-h-half" />
                                </div>
                            </div>
                        )}


                    </>
                )}




                {activeTab === 'eSecurity' && (
                    <section className="container-fluid mb-5">
                        <h1 className="text-center head-h-divtopic pt-5 py-5" style={{ color: "#2f4d2a" }}>
                            <span style={{ color: "#f28c28", fontWeight: "bolder" }}> Smart E-security </span>
                        </h1>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        </div>


                    </section>

                )}



                <Footerist />
            </div>



        </>
    );
}
