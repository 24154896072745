import React, { useState, useEffect } from 'react';
import './product.css';
import { categories, products } from './productData';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footerist from '../A-LAYOUT/footer';


function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const initialSelectedCategoryId = categories[0]?.id

  const [selectedCategory, setSelectedCategory] = useState(initialSelectedCategoryId);

  const initialSubSelectedCategoryId = categories[0]?.id
  const [selectedSubcategory, setSelectedSubcategory] = useState(initialSubSelectedCategoryId);



  const filteredProducts = selectedCategory
    ? products.filter(
      (product) => product.category === selectedCategory &&
        (!selectedSubcategory || product.subcategory === selectedSubcategory)
    )
    : products;



  return (
    <>






      <div className="container products-container mt-5 mb-5" style={{ overflow: "hidden" }}>
        <h1 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>We offer  a  range of  Products  in</h1>

        <div className="categories-container  flex flex-wrap justify-center gap-1">
          {categories.map((category) => (
            <div
              key={category.id}
              className={`category-card1 ${selectedCategory === category.id ? 'selected' : ''}`}
              onClick={() => {
                setSelectedCategory(category.id);
                setSelectedSubcategory(null);
              }}
            >
              <img src={category.image} alt={category.name} className="category-image1" />
              <h5 className="category-name1 mt-4">{category.name}</h5>
            </div>
          ))}
        </div>

        {selectedCategory && (
          <div className="subcategories-container">
            {categories.find((cat) => cat.id === selectedCategory).subcategories.map((subcategory) => (
              <div
                key={subcategory.id}
                className={`subcategory-card ${selectedSubcategory === subcategory.id ? 'selected' : ''}`}
                onClick={() => setSelectedSubcategory(subcategory.id)}
              >
                <button className="subcategory-name">{subcategory.name}</button>
              </div>
            ))}
          </div>
        )}



        <div className="row mt-5 " style={{ display: "flex", justifyContent: "space-evenly" }}>
          {filteredProducts.map((product) => (<>
            <div className="card pro-car-ist-common col-lg-3 col-md-3 col-sm-12 mb-5" key={product.id}>
              <img src={product.images[0]} className="card-img-top mt-4 mb-4" alt={product.name} />
              <div className="card-body">
                <h5 className="card-title"> {product.name}</h5>
                <h6 className="card-title mb-4">{product.name1}</h6>
                <p className="card-text"> {product.description}</p>
                <div className="row">

                </div>
              </div>
            </div>

          </>
          ))}
        </div>
      </div>





      <Footerist />
    </>
  );
}

export default Products;
