
import React, { useEffect } from "react";
import "./HOME.css";
import jQuery from "jquery";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Footerist from '../A-LAYOUT/footer';



import 'swiper/swiper-bundle.css';


import logoimg1 from '../Z-IMAGES/IST-Clients-logo/DMS.png'
import logoimg2 from '../Z-IMAGES/IST-Clients-logo/Delhivery_Logo_Dark.c267e3f.png'
import logoimg3 from '../Z-IMAGES/IST-Clients-logo/ESS+logo-268w.png'
import logoimg4 from '../Z-IMAGES/IST-Clients-logo/FedEX-logo.png'
import logoimg5 from '../Z-IMAGES/IST-Clients-logo/KAV HI.png'
import logoimg6 from '../Z-IMAGES/IST-Clients-logo/Logosrec.png'
import logoimg7 from '../Z-IMAGES/IST-Clients-logo/My-sports-life-logo1.png'
import logoimg8 from '../Z-IMAGES/IST-Clients-logo/SKB.png'
import logoimg9 from '../Z-IMAGES/IST-Clients-logo/TNAU-Logo_footer_2-1-e1678976136888.png'
import logoimg10 from '../Z-IMAGES/IST-Clients-logo/sai-consultancy-logo.png'

import logoimg11 from '../Z-IMAGES/IST-Clients-logo/VTC-final-LOGO-2.png'
import logoimg12 from '../Z-IMAGES/IST-Clients-logo/bio-line.jpg'
import logoimg13 from '../Z-IMAGES/IST-Clients-logo/bob-logo.png'
import logoimg14 from '../Z-IMAGES/IST-Clients-logo/annax-big-6683ef3745ccd.webp'
import logoimg15 from '../Z-IMAGES/IST-Clients-logo/cycle.jpg'
import logoimg16 from '../Z-IMAGES/IST-Clients-logo/kcs-logo.png'
import logoimg17 from '../Z-IMAGES/IST-Clients-logo/logo_tnau_main.jpg'
import logoimg18 from '../Z-IMAGES/IST-Clients-logo/mayu-flower-logo.png'
import logoimg19 from '../Z-IMAGES/IST-Clients-logo/nak-logo-1.png'
import logoimg20 from '../Z-IMAGES/IST-Clients-logo/pattern-dies-india-technologies-logo-120x120.gif'

import logoimg21 from '../Z-IMAGES/IST-Clients-logo/q-plus-logo.jpg'
import logoimg22 from '../Z-IMAGES/IST-Clients-logo/rainbow-logo.png'
import logoimg23 from '../Z-IMAGES/IST-Clients-logo/KAV HI.png'
import logoimg24 from '../Z-IMAGES/IST-Clients-logo/sai-consultancy-logo.png'
import logoimg25 from '../Z-IMAGES/IST-Clients-logo/shiprocket_logo.svg'
import logoimg26 from '../Z-IMAGES/IST-Clients-logo/shriram-logo.svg'
import logoimg27 from '../Z-IMAGES/IST-Clients-logo/versa-Drives-logo.png'
import logoimg28 from '../Z-IMAGES/IST-Clients-logo/vishnu-clothing-company-logo.png'
import logoimg29 from '../Z-IMAGES/IST-Clients-logo/bio-line.jpg'
import logoimg30 from '../Z-IMAGES/IST-Clients-logo/cycle.jpg'

import westrongly from '../Z-IMAGES/We Strongly believe on right.png'


// iconsssssssssss
import AOS from 'aos';
import 'aos/dist/aos.css';
import TimelineISt from "./timeline";
import Sliderist from "./slider";








export default function Isthome() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


  



    AOS.init();

    (function ($) {
        $.fn.countTo = function (options) {
            options = options || {};

            return $(this).each(function () {
                var settings = $.extend({}, $.fn.countTo.defaults, {
                    from: $(this).data('from'),
                    to: $(this).data('to'),
                    speed: $(this).data('speed'),
                    refreshInterval: $(this).data('refresh-interval'),
                    decimals: $(this).data('decimals')
                }, options);

                var loops = Math.ceil(settings.speed / settings.refreshInterval),
                    increment = (settings.to - settings.from) / loops;

                var self = this,
                    $self = $(this),
                    loopCount = 0,
                    value = settings.from,
                    data = $self.data('countTo') || {};

                $self.data('countTo', data);

                if (data.interval) {
                    clearInterval(data.interval);
                }

                function updateTimer() {
                    value += increment;
                    loopCount++;

                    if (loopCount >= loops) {
                        value = settings.to;
                        loopCount = loops; // Ensure loopCount does not exceed loops
                    }

                    render(value);

                    if (typeof (settings.onUpdate) === 'function') {
                        settings.onUpdate.call(self, value);
                    }

                    if (loopCount >= loops) {
                        $self.removeData('countTo');
                        clearInterval(data.interval);

                        if (typeof (settings.onComplete) === 'function') {
                            settings.onComplete.call(self, value);
                        }
                    }
                }

                function render(value) {
                    var formattedValue = settings.formatter.call(self, value, settings);
                    $self.html(formattedValue);
                }

                render(value); // Render the initial value

                data.interval = setInterval(updateTimer, settings.refreshInterval);
            });
        };

        $.fn.countTo.defaults = {
            from: 0,
            to: 0,
            speed: 4000,
            refreshInterval: 100,
            decimals: 0,
            formatter: formatter,
            onUpdate: null,
            onComplete: null
        };

        function formatter(value, settings) {
            return value.toFixed(settings.decimals);
        }
    }(jQuery));

    jQuery(function ($) {
        $('.count-number').data('countToOptions', {
            formatter: function (value, options) {
                return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
            }
        });

        $('.timer').each(count);

        function count(options) {
            var $this = $(this);
            options = $.extend({}, options || {}, $this.data('countToOptions') || {});
            $this.countTo(options);
        }
    });



    return (
        <>
        
            <body>

            <Sliderist/>





                <TimelineISt />




                <section className="text-center">
                    <h1 className="head-h-divtopic py-5" >
                        <span style={{ color: "#2f4d2a", fontWeight: "bolder" }}> Quality     </span> never goes out of style.
                    </h1>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={westrongly} alt="Girl in a jacket" className="img-fluid" style={{ height: "fit-content", width: "900px" }} />

                    </div>

                </section>



                <section class="container text-center ">
                {/* <h1 className="head-h-divtopic " >
                                    <span style={{ color: "#2f4d2a", fontWeight: "bolder" }}>We exchange      </span> trust with..
                                </h1> */}
                    <div class="row text-center" data-aos="fade-up" data-aos-offset="100" data-aos-delay="10" data-aos-duration="1000" data-aos-easing="ease-in-out">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="counter">
                                <span class="timer count-title count-number" data-from="0" data-to="19" data-speed="4000"></span>k+
                                <p class="count-text">Product's Delivered</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="counter">
                                <span class="timer count-title count-number" data-from="0" data-to="200" data-speed="4000"></span>+
                                <p class="count-text">Successful Projects Execution</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="counter">
                                <span class="timer count-title count-number" data-from="0" data-to="13" data-speed="4000"></span>Yrs+
                                <p class="count-text">Expertise</p>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="logo-scroll-ist">
                    <section className="sectionsix">
                        < section className='logoscroll'>

                            <div className="common-top-div">

                                <h1 className="head-h-divtopic " >
                                    <span style={{ color: "#2f4d2a", fontWeight: "bolder" }}>We exchange      </span> trust with..
                                </h1>
                                <h3 className="head-h-divtopic " >

                                </h3>
                              

                            </div>





                            <div class="Marquee">
                                <div class="Marquee-content">
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg1} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg2} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg3} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg4} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg5} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg6} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg7} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg8} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg9} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg10} className=" scroll-logo-home-nystai" /></div>


                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg1} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg2} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg3} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg4} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg5} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg6} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg7} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg8} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg9} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg10} className=" scroll-logo-home-nystai" /></div>
                                </div>
                            </div>

                            <div class="Marquee">
                                <div class="Marquee-content">
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg11} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg12} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg13} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg14} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg15} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg16} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg17} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg18} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg19} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg20} className=" scroll-logo-home-nystai" /></div>


                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg11} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg12} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg13} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg14} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg15} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg16} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg17} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg18} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg19} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg20} className=" scroll-logo-home-nystai" /></div>
                                </div>
                            </div>

                            <div class="Marquee">
                                <div class="Marquee-content">
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg21} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg22} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg23} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg24} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg25} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg26} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg27} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg28} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg29} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg30} className=" scroll-logo-home-nystai" /></div>



                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg21} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg22} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg23} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg24} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg25} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg26} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg27} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg28} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg29} className=" scroll-logo-home-nystai" /></div>
                                    <div class="Marquee-tag"> <img alt="NYSTAI" src={logoimg30} className=" scroll-logo-home-nystai" /></div>

                                </div>
                            </div>





                        </section>
                    </section>
                </section>




                <Footerist />

            </body >

        </>
    );
}
