import React, { useState, useRef, useEffect } from 'react';
import './supportpage.css';
import qr from '../Z-IMAGES/supportpage.png'; // Ensure the correct path
import emailjs from '@emailjs/browser';
import { Toaster, toast } from 'react-hot-toast';
import Footerist from '../A-LAYOUT/footer';




export default function Supportist() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);



  const [formData, setFormData] = useState({
    name: '',
    companyname: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      const templateParams = {
        to_name: 'IST',
        name: formData.name,
        companyname: formData.companyname,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        message: formData.message
      };
      emailjs.send('service_vmyk5jw', 'template_a7nbnld', templateParams, 'MxsFNiKhu-Ek8odBL')
        .then(
          () => {
            toast.success('Thank you for getting in touch with us, We will review your message & will get back to you shortly.', {
              style: {
                border: '2px solid #4caf50',
                padding: '10px',
                color: '#4caf50',
                borderRadius: '8px',
                background: '#fff',
              },
            });
            setFormData({
              name: '',
              companyname: '',
              email: '',
              phoneNumber: '',
              message: ''
            });
          },
          (error) => {
            console.log('FAILED...', error.text);
            toast.error('Failed to send message', {
              style: {
                border: '2px solid #ff1100',
                padding: '10px',
                color: '#ff1100',
                borderRadius: '8px',
                background: '#fff',
              },
            });
          }
        );
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <>
    <div className="container-fluid frcontainer">

      


      



      <Toaster />
      <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <h1 className="hed2 under-line">Contact us</h1>
        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 order-3 order-lg-1">
          <div className="map-container" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <h2 className="hed2">We are Located at</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62649.061534722394!2d76.94614100000001!3d11.071103600347833!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f63c6460158f%3A0x455366ec696464f!2sIST%20Retail!5e0!3m2!1sen!2sus!4v1715862352675!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="map"
              style={{ height: '300px', marginBottom: '10px' }}
            ></iframe>
            <h2 className="hed2">Operating Hours</h2>
            <h3 className="hed2">Monday to Saturday: 9:00 AM - 06:00 PM</h3>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 order-2 order-lg-2 ">
          <div className="contact-sec">
            <div className="contact-info">
              <h2 className="hed2"><b>+91 99438 94429</b></h2>
              <h2 className="hed2"><b>contact@istretail.com</b></h2>
            </div>
            <div className="qr-section">
              <h2 className="hed2">Scan / Click to WhatsApp us</h2>
              <div className="qr-img-container">
                <div className="qr-img">
                  <a href='https://api.whatsapp.com/send?phone=919943894429&text=' target="_blank" rel="noopener noreferrer">
                    <img src={qr} alt="QR Code" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 order-1 order-lg-3" >
          <div className="form-container">
            <h2 className="hed2">Get in touch with us</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-group">
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  className={errors.name ? 'error' : ''}
                />
                {errors.name && <span className="error-text">{errors.name}</span>}
              </div>
              <div className="form-group">
                <label htmlFor="companyname">Your Company Name</label>
                <input
                  type="text"
                  id="companyname"
                  name="companyname"
                  placeholder="(Optional)"
                  value={formData.companyname}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Your Official Mail ID*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Official Mail Address"
                  value={formData.email}
                  onChange={handleChange}
                  className={errors.email ? 'error' : ''}
                />
                {errors.email && <span className="error-text">{errors.email}</span>}
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">Contact Number*</label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Your Contact Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className={errors.phoneNumber ? 'error' : ''}
                />
                {errors.phoneNumber && <span className="error-text">{errors.phoneNumber}</span>}
              </div>
              <div className="form-group">
                <label htmlFor="message">Enquiry*</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Please fill your valuable Enquiry / Feedback / Testimonial"
                  value={formData.message}
                  onChange={handleChange}
                  className={errors.message ? 'error' : ''}
                />
                {errors.message && <span className="error-text">{errors.message}</span>}
              </div>
              <div className="email-btn-cont">
                <button type="submit" className="email-btn btn-center">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

     
    </div>
    <Footerist/>
    </>
  );
}

