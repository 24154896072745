import { useEffect, useState } from 'react';
import React  from "react";
import './slider.css'


export default function Sliderist(){

    const [currentBanner, setCurrentBanner] = useState(1);

    useEffect(() => {
        const bannerTimer = setInterval(() => {
            setCurrentBanner(prevBanner => (prevBanner % 5) + 1);
        }, 4000);

        return () => {
            clearInterval(bannerTimer);
        };
    }, []);

    useEffect(() => {
        const nextBanner = document.getElementById(`banner${currentBanner}`);
        if (nextBanner) nextBanner.click();
    }, [currentBanner]);

    return(
        <>
        
        
        <body className='hover-tab-body-nystai mt-2'>
                    <section id="section-1">
                        <div className="content-slider">

                            <input type="radio" id="banner1" className="sec-1-input" name="banner" defaultChecked />
                            <input type="radio" id="banner2" className="sec-1-input" name="banner" />
                            <input type="radio" id="banner3" className="sec-1-input" name="banner" />
                            <input type="radio" id="banner4" className="sec-1-input" name="banner" />
                            <input type="radio" id="banner5" className="sec-1-input" name="banner" />

                            <div class="slider">
                                <div id="top-banner-1" class="banner">
                                    <div class="banner-inner-wrapper">
                                        <h2>SMART SECURITY</h2>


                                    </div>
                                </div>
                                <div id="top-banner-2" class="banner">
                                    <div class="banner-inner-wrapper">
                                        <h2>SUPPLY CHAIN<br /> PARTNERING</h2>

                                    </div>
                                </div>
                                <div id="top-banner-3" class="banner">
                                    <div class="banner-inner-wrapper">
                           
                            <h2>  SPECIFIC PURPOSE <br /> ELECTRONICS<br/> MANUFACTURING</h2>

                                    </div>
                                </div>
                                <div id="top-banner-4" class="banner">
                                    <div class="banner-inner-wrapper">
                                        <h2> CLOUD SPACE</h2>

                                    </div>
                                </div>
                                <div id="top-banner-5" class="banner">
                                    <div class="banner-inner-wrapper">
                                        <h2>  RETAIL THROUGH <br />E-COMMERCE</h2>

                                    </div>
                                </div>
                            </div>
                            <nav>
                                <div className="home-slider-navigation mt-3">
                                    <label htmlFor="banner1">   <span className="nav-point active" data-index="0">01</span></label>
                                    <label htmlFor="banner2">   <span className="nav-point" data-index="1">02</span></label>
                                    <label htmlFor="banner3"> <span className="nav-point" data-index="2">03</span></label>
                                    <label htmlFor="banner4"> <span className="nav-point" data-index="3">04</span></label>
                                    <label htmlFor="banner5">   <span className="nav-point" data-index="4">05</span></label>
                                </div>

                            </nav>



                        </div>
                    </section>
                </body>
        
        
        
        
        </>
    )
}