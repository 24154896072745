import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import Istretailcom from './component/A-LAYOUT/navbar';






function App() {
  return (
    <>

      <Istretailcom />

    </>
  );
}

export default App;
