import React from "react";
import './navbar.css';
import ISTLOGO from '../Z-IMAGES/IST-LOGO (2).jpeg';
import { Outlet, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServicestack, faWhatsapp, faYoutube, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faBasketShopping, faEject, faHouse, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function LAYOUT() {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <>




            <nav className="navbar navbar-expand-lg bg-body-light sticky-top">
                <div className="container-fluid ">
                    <a className="navbar-brand" href="#">
                        <img src={ISTLOGO} alt="istlogo" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>





                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">


                                <Link to="/isthome" className={`text-nav-ist ${isActive('/isthome')}`}>
                                    <FontAwesomeIcon style={{ color: '#f28c28' }} icon={faHouse} className="me-1" />Home
                                </Link>

                            </li>
                            <li className="nav-item">
                                <Link to="/Aboutusist" className={`text-nav-ist ${isActive('/Aboutusist')}`} >
                                    <FontAwesomeIcon style={{ color: '#f28c28' }} icon={faEject} className="me-1" />About us
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/istproduct" className={`text-nav-ist ${isActive('/istproduct')}`} >
                                    <FontAwesomeIcon style={{ color: '#f28c28' }} icon={faBasketShopping} className="me-1" />Products
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/istservices" className={`text-nav-ist ${isActive('/istservices')}`} >
                                    <FontAwesomeIcon style={{ color: '#f28c28' }} icon={faServicestack} className="me-1" />Services
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/istsupport" className={`text-nav-ist ${isActive('/istsupport')}`} >
                                    <FontAwesomeIcon style={{ color: '#f28c28' }} icon={faPhone} className="me-1" />Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="Social-media ">
                        <a href="https://www.facebook.com/ISTforyou" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} style={{ color: '#1877F2' }} className="fonticonistsocial" />
                        </a>
                        <a href="https://www.instagram.com/ist_retail/?hl=en" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} style={{ color: '#E4405F' }} className="fonticonistsocial" />
                        </a>
                        <a href="https://youtube.com/@ist_retail?si=Rt0R3LzJ3iaD7yTv" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYoutube} style={{ color: "#FF0000" }} className="fonticonistsocial" />
                        </a>

                        <a href="https://wa.me/919943894429" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#25D366" }} className="fonticonistsocial" />
                        </a>
                    </div>
                </div>

            </nav>


            <Outlet />
        </>
    );
}

